/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color-light;
    background-color: $background-dark;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img, picture {
    max-width: 100%;
    vertical-align: middle;
}

img.full, img.half {
    width: 100%;
    border: 1px solid $grey-color-dark;
    border-radius: 4px;
}

picture:not(:last-of-type) img.half {
    margin-bottom: 10px;
}

@media (min-width: 500px) {
    img.half {
        width: calc(50% - 7px);
        margin-right: 10px;
        margin-bottom: 10px;
        float:left;
    }

    picture:nth-of-type(2n) img.half  {
      margin-right: 0px;
    }
}

/**
 * Video Embeds
 */

iframe.vimeo_embed {
  width: 100vw;
  height: 56.25vw;
}

img.latest_video, picture.latest_video {
    width: 133px;
    height: 75px;
}

/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid lighten($background-light, 15%);
    border-radius: 3px;
    background-color: lighten($background-light, 10%);
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}

.video_full_16_9 {
    width: 100%;
    height: calc((800px - 30px * 2) * 9 / 16);
}
@media screen and (max-width: 800px) {
    .video_full_16_9 {
        height: calc((100vw - 30px) * 9 / 16);
    }
}

/**
 * Clearfix
 */
%clearfix:after {
    content: "";
    display: table;
    clear: both;
}



/**
 * Icons
 */
.icon > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}
